<template>
  <el-row class="device-info" :gutter="20">
    <el-col :span="8">
      <el-form label-width="80px">
        <el-form-item label="设备编号">
          <p>{{device.deviceUuid}}</p>
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input v-model="device.deviceName"></el-input>
        </el-form-item>

        <el-form-item label="已有支付">
          <span v-if="device.wechatPay ===0 && device.aliPay ===0">无配置</span>
          <i class="iconfont icon-zhifubao" style="color: #108ee9;" v-if="device.aliPay ===1"></i>
          <i class="iconfont icon-weixin" style="color: #1AAD19;" v-if="device.wechatPay ===1"></i>
        </el-form-item>
        <el-form-item label="所属分组">
          <el-select v-model="device.groupUuid" placeholder="请选择活动区域">
            <el-option :label="group.name" :value="group.uuid" v-for="group in groups"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="device.dutyUserName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="device.dutyUserPhone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateDeviceInfo">保存</el-button>
          <el-button type="primary" @click="closeInfo" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="12">
      <el-form label-width="80px">
        <el-form-item label="地图信息">
          <map-container class="map" :lat.sync="device.lat" :lng.sync="device.lng" :province.sync="device.province"
                         :city.sync="device.city" :address.sync="device.address"></map-container>
        </el-form-item>
        <el-form-item label="设备地址">
          {{device.province}}/{{device.city}}/ {{device.address}}
        </el-form-item>


      </el-form>
    </el-col>
  </el-row>

</template>

<script>
  import {fillObj} from "@/util/checkLogin";
  import {deviceUpdate} from "@/api/equipment/device";
  import MapContainer from '@/components/Map'

  export default {
    name: '',
    components: {
      MapContainer
    },
    mounted: function () {
    },
    data: function () {
      return {
        groups: [],
        device: {
          deviceUuid: '',
          deviceName: '',
          wechatPay: 0,
          aliPay: 0,
          groupUuid: '',
          province: '',
          city: '',
          address: '',
          lat: '',
          lng: '',
          dutyUserName: '',
          dutyUserPhone: '',
        },
      }
    },
    methods: {
      resetInfo(device, groups) {
        this.groups = groups || []
        fillObj(this.device, device)
      },

      updateDeviceInfo() {
        this.device.lat += ''
        this.device.lng += ''
        deviceUpdate(this.device).then(res => {
          this.$message.success('更新成功')
          this.closeInfo()
        })
      },

      closeInfo() {
        this.$emit(`close`)
      },
    },
  }
</script>

<style lang="scss">
  .device-info {
    width: 100%;
    padding: $padding;

    .map {
      height: 300px;
    }
  }
</style>
